export function toSso() {
    let flag = location.host.indexOf("sit-");
    if (flag != -1) {
        location.href = "https://sit-sso.sfcollect.com/#/?sysCode=screen_hb"
    } else {
        location.href = "https://sso.sfcollect.com/#/?sysCode=screen_hb"
        // location.href = "http://localhost:9528/#/?sysCode=screen_hb"
    }
}
// 保存Token
export function getToken() {
    return window.localStorage.getItem('access_token')
}

export function setToken(tonken) {
    window.localStorage.setItem('access_token', tonken)
}

export function removeToken() {
    window.localStorage.removeItem('access_token')
}

export function getToken2() {
    return window.localStorage.getItem('access_token2')
}
export function setToken2(tonken) {
    window.localStorage.setItem('access_token2', tonken)
}


// 保存用户信息
export function getUser(){
    return JSON.parse(window.localStorage.getItem('user'))
}

export function getclientId() {
    return sessionStorage.getItem('clientId')
}

export function setclientId(clientId) {
    return sessionStorage.setItem("clientId", clientId);
}

export function setUser(user){
    window.localStorage.setItem('user', JSON.stringify(user))
}

export function removeUser(){
    window.localStorage.removeItem('user')
}

export function getData(name) {
    return JSON.parse(sessionStorage.getItem(name))
}

export function setData(name, data) {
    return sessionStorage.setItem(name, JSON.stringify(data))
}

// 保存下载权限信息
export function getIsCanDownload() {
    return JSON.parse(localStorage.getItem('isCanDownload'))
}

export function setIsCanDownload(isCanDownload) {
    return localStorage.setItem("isCanDownload", JSON.stringify(isCanDownload));
}

export function removeIsCanDownload(){
    localStorage.removeItem('isCanDownload')
}