import request from '@/utils/request'

export function createUser(data) {
  return request({
    url: `/user/add`,
    method: 'post',
    data
  })
}

export function updateUser(data) {
  return request({
    url: `/user/update`,
    method: 'post',
    data
  })
}

export function userReset(account) {
  return request({
    url: `/user/reset/${account}`,
    method: 'get'
  })
}

export function removeForbidden(account) {
  return request({
    url: `/user/removeForbidden/${account}`,
    method: 'get'
  })
}



export function getUserInfo() {
  // return request({
  //     url: `/api/user/resetAll`,
  //     method: 'post',
  //     data: ids
  // })
}

// 获取用户信息
export function userInfo() {
  return request({
    url: `/user/userInfo`,
    method: 'get'
  })
}

export function userGetPage(data) {
  return request({
    url: `/user/getPage`,
    method: 'post',
    data
  })
}

// 修改当前登录用户密码
export function updatePwd(data) {
  return request({
    url: `/user/change`,
    method: 'post',
    data
  })
}

// 修改易流配置
export function e6Update(data) {
  return request({
    url: `/e6Key/update`,
    method: 'post',
    data
  })
}
// ACC获取
export function accGetPage(data) {
  return request({
    url: `/e6Config/getPage`,
    method: 'post',
    data
  })
}
// 编辑确认
export function accUpdate(data) {
  return request({
    url: `/e6Config/update`,
    method: 'post',
    data
  })
}
// 新增确认
export function accAdd(data) {
  return request({
    url: `/e6Config/add`,
    method: 'post',
    data
  })
}
// 删除
export function accDelete(id) {
  return request({
    url: `/e6Config/delete/${id}`,
    method: 'get',
  })
}
