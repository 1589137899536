import axios from "axios";
import { MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import Bus from "@/utils/bus";
import { toSso } from '@/utils/auth'

let flag = true;
let has409 = false;

const service = axios.create({
  baseURL: ["development"].includes(process.env.NODE_ENV)
    ? process.env.VUE_APP_BASE_API
    : window.global.baseUrl,
  // timeout: 30000,
  timeout: 600000,
});

let loadingInstance;
service.interceptors.request.use(
  (config) => {
    loadingInstance =
      config.loading &&
      Loading.service({
        fullscreen: true,
        text: "正在加载中",
      });
    if (store.getters.clientId) {
      config.headers["clientId"] = `${store.getters.clientId}`;
    }
    if (
      store.getters.ipParams &&
      Object.keys(store.getters.ipParams).length > 0
    ) {
      config.headers["Ip-Addr"] = store.getters.ipParams.cip;
      config.headers["Ip-Name"] = encodeURIComponent(
        store.getters.ipParams.cname
      );
    }
    // console.log('config===>',config)
    const ssoToken = getToken();
    if(ssoToken){
        config.headers["token"] = ssoToken;
    } else if (store.getters.token) {
      config.headers["token"] = `${getToken()}`;
    }else{
      if(config.url !== '/getImageCode'&&config.url !== '/getPublicKey'&&config.url !== '/user/login'){
        // Message({
        //   message:`token 失效！`,
        //   type: "error",
        //   duration: 5 * 1000,
        // });
        store.dispatch("user/resetToken").then(() => {
          location.href = "/#/login";
            // toSso();
        });
        return 
      }
    }
    if (config.url == "/monitor/getData") {
      if (
        config.data.type != "warehouseTemperature" &&
        config.data.type != "vehicleInfoOfCurrentDay" &&
        // config.data.type != "supplierDeliveryTask" &&
        config.data.type != "supplierDeliveryTaskWlyd"&&
        config.data.type != "alarmInfoList"&&
        config.data.type != "todayRefundSummary"&&
        config.data.type != "todayRefund"&&
        config.data.type != "todayRefundTrend"&&
        config.data.url != "/ICRFS/GetOrderTodaySummary?"&&
        config.data.url != "/ICRFS/GetRefundTodayTotalSummary?queryStatus=100"
        
      ) {
        let value = 0;
        //设置最后一次请求接口时间为0
        store.dispatch("global/changeGlobal", { key: "time", value });
      }
    } else {
      let value = 0;
      //设置最后一次请求接口时间为0
      store.dispatch("global/changeGlobal", { key: "time", value });
    }
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    loadingInstance && loadingInstance.close();
    if (has409) return;
    const res = response.data;
    if (res.code && res.code !== 0 && res.code !== 20000) {
      if (res.code === 2 || res.messages === "Token无效") {
        MessageBox.confirm(
          "您的登录已经失效，请重新登录，或点击取消停留在当前页",
          "登出确认",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.href = "/#/login";
              // toSso();
          });
        });
      }
      if(res.code === 302){
          location.href = res.messages;
      } else if (res.code === 1) {
        if (flag) {
          Bus.$emit("getImageCode");
        }

        Message({
          message: `${res.messages} 请重新输入!`,
          // type: "error",
          duration: 5 * 1000,
        });
      }
      return Promise.reject(new Error(res.message || "Error"));
    } else if (response.request.responseType === "blob") {
      return response;
    } else {
      if (!flag) {
        flag = true;
      }
      return res;
    }
  },
  (error) => {
    console.log(JSON.stringify(error));
    loadingInstance && loadingInstance.close();
    if (has409) return;
    if (
      (error && error.response && error.response.status !== 200) ||
      (error && error.response && error.response.data.code !== 0)
    ) {
      Message({
        message:
          // error.message ||
          (error.response && error.response.data && error.response.data.messages) || error.response.statusText,
        type: "error",
        duration: 5 * 1000,
      });
      let state = error.response.status;
      if ([401, 403].includes(state)) {
        store.dispatch("user/resetToken").then(() => {
          location.href = "/#/login";
            // toSso();
        });
      }
      if ([409].includes(state)) {
        has409 = true;
        setTimeout(() => {
          store.dispatch("user/resetToken").then(() => {
            location.href = "/#/login";
              // toSso();
            has409 = false;
          });
        }, 3000);
      }
    }
    return Promise.reject(error);
  }
);

export default service;
