import { formatFloat } from "@/utils";
/**
 * 解析后端给的国际时间（2020-06-22T03:19:01.564+0000）
 */
 export function formatUTCTime(time, fl = false) {
    if (!time) {
        return
    }
    function addDateZero(num) {
        return (num < 10 ? '0' + num : num)
    }
    const d = new Date(time)
    let formatdatetime
    if (fl) {
        formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate()) + ' ' + addDateZero(d.getHours()) + ':' + addDateZero(d.getMinutes()) + ':' + addDateZero(d.getSeconds())
    } else {
        formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
    }
    return formatdatetime
}

// 数字格式化为千分位并保留两位小数
export function Format (val, n=2) {
    if (!val) {
        let res
        if (n) {
            res ='0.'
            while(n) {
                n--;
                res += '0'
            }
        } else {
            res = '0'
        }
        return res 
    }
    return (formatFloat(val, n) + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}