import request from '@/utils/request'

export function getPublicKey(params) {
    return request({
        url: `/getPublicKey`,
        method: 'get',
        params
    })
}

export function loginOauth(data) {
    return request({
        url: `/user/login`,
        method: 'post',
        data,
        // loading: true
    })
}

export function logout(params) {
    return request({
        url: `/user/logout`,
        method: 'get',
        params,
        loading: true
    })
}

/**
 * 获取验证码
 */
export function getImageCode() {
    return request({
        url: `/getImageCode`,
        method: 'get',
        responseType: 'arraybuffer'
    }).then(res => {
        return 'data:image/png;base64,' + btoa(
            new Uint8Array(res)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
    })
}

/**
 * 登录日志列表
 */
export function getJournalPage(data) {
    return request({
        url: `/loginLog/getPage`,
        method: 'post',
        data
    })
}
