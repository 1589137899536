// import md5 from 'js-md5';
// import { getToken2 } from './auth'

/**
 * 开启定时器 查询温度
 */
let TimeId = null
 export function QueryTemperature(fn, time) {
   if (TimeId) {
     clearInterval(TimeId)
     TimeId = null
   }
  TimeId = setInterval(() => {
      fn()
    }, time)
 }

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function observerDomResize(dom, callback) {
  const MutationObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;

  const observer = new MutationObserver(callback);

  observer.observe(dom, {
    attributes: true,
    attributeFilter: ["style"],
    attributeOldValue: true,
  });

  return observer;
}

export function uuid(hasHyphen) {
  return (hasHyphen
    ? "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    : "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx"
  ).replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

/**
 * 生成clientId
 * @param {number} len 生成指定长度的clientId
 * @param {number} radix clientId进制数
 */
export function ClientId(len, radix) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
    ""
  );
  const clientId = [];
  let i;
  radix = radix || chars.length;

  if (len) {
    for (i = 0; i < len; i++) clientId[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;

    clientId[8] = clientId[13] = clientId[18] = clientId[23] = "-";
    clientId[14] = "4";

    for (i = 0; i < 36; i++) {
      if (!clientId[i]) {
        r = 0 | (Math.random() * 16);
        clientId[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return clientId.join("");
}

/**
 * 处理entitys
 */
export function handleEntitys(arr = []) {
  const params = {
    entity: {},
    ranges: [],
  };
  arr.map((item) => {
    if (item.querySelect.queryType === "RANGE") {
      if (item.querySelect.feildType === "DATE") {
        params.ranges.push({
          from: item.queryValue[0],
          to: item.queryValue[1],
          typeName: "java.util.Date",
          field: item.querySelect.prop,
        });
      }
    } else {
      if (item.queryValue !== "" && item.querySelect !== "") {
        params.entity[item.querySelect.prop] = item.queryValue;
      }
    }
  });
  return params;
}

// option4 下拉框数据格式
export function treeFunc(data) {
  if (data.length > 0) {
    data.forEach((item) => {
      item.value = item.name;
      item.label = item.name;
      item.children = item.childs;
      if (item.children && item.children.length > 0) {
        const children = item.children;
        treeFunc(children);
      } else {
        delete item.children;
      }
    });
    return data;
  }
}


export function treeFunc2(data) {
  if (data.length > 0) {
    data.forEach((item) => {
      item.value = item.categoryName;
      item.label = item.categoryName;
      // item.children = item.children;
      if (item.children && item.children.length > 0) {
        const children = item.children;
        treeFunc2(children);
      } else {
        delete item.children;
      }
    });
    return data;
  }
}

export function transformListToTree(list, id, parentId) {
  const temp = {};
  const tree = [];
  list.map((item) => {
    temp[item[id]] = item;
  });
  for (const i in temp) {
    const item = temp[i];
    item.label = item.name;
    if (temp[item[parentId]]) {
      if (!temp[item[parentId]].children) {
        temp[item[parentId]].children = [];
      }
      temp[item[parentId]].children.push(item);
    } else {
      tree.push(item);
    }
  }
  return tree;
}

//时间戳转换方法    date:时间戳数字
export function formatDate(date) {
  let d = new Date(date);
  let YY = d.getFullYear() + "";
  let MM = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  return YY + MM;
}
//时间戳转换方法    date:时间戳数字
export function formatDateVal(date) {
  let d = new Date(date);
  let YY = d.getFullYear() + "";
  let MM = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  return YY + "/" + MM;
}
//时间戳转换方法    date:时间戳数字
export function formatDateHym(time) {
  let date = new Date(time);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
  let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
  let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
  let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
  let strDate = Y+M+D+h+m+s;
  return strDate;
}


/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * 将数字取整为10的倍数
 * @param {Number} num 需要取整的值
 * @param {Boolean} ceil 是否向上取整
 * @param {Number} prec 需要用0占位的数量
 */
export const formatInt = (num, prec = 2, ceil = true) => {
  const len = String(num).length;
  if (len <= prec) {
    return num;
  }

  const mult = Math.pow(10, prec);
  return ceil ? Math.ceil(num / mult) * mult : Math.floor(num / mult) * mult;
};

// 数字格式化为千分位
export const format = (num) => {
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}

/**
 * 四舍五入并保留两位小数 num为传入的值，n为保留的小数位
 */

export function formatFloat(num, n = 2, thousand) {
  let f = parseFloat(num);
  if (isNaN(f)) return;
  f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
  var s = f.toString();
  var rs = s.indexOf(".");
  // 判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + n) {
    s += "0";
  }
  if (thousand) {
    console.log(s)
    return format(s)
  }
  if (n) {
    return s;
  } else {
    return Number(s);
  }
}

export function formatFloatVal(num, n, thousand) {
  let f = parseFloat(num);
  if (isNaN(f)) return;
  f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
  var s = f.toString();
  var rs = s.indexOf(".");
  // 判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = s.length;
    s += '';
  }
  while (s.length <= rs + n) {
    s += '';
  }
  if (thousand) {
    console.log(s)
    return format(s)
  }
  if (n) {
    return s;
  } else {
    return Number(s);
  }
}


/* 
  递归西藏城市json数据
  obj 为传入的json值
*/

export function cityRecursion(obj) {
    let regionOptions = []
    for( let i in obj ) { 
        if(i !== 'AREAID'&&i !== 'NAMECN'){
            if ( typeof obj[i] === "object" ) { 
                let children1 = []
                for( let i2 in obj[i] ) {
                    if(i2 !== 'AREAID'&&i2 !== 'NAMECN'){
                        if ( typeof obj[i][i2] === "object" ) {
                            let children2 = []
                            for( let i3 in obj[i][i2] ) {
                                if(i3 !== 'AREAID'&&i3 !== 'NAMECN'){
                                    if ( typeof obj[i][i2][i3] === "object" ) {
                                        children2.push({
                                            value: i3,
                                            label: i3,
                                        })
                                    }
                                }
                            }
                            children1.push({
                                 value: i2,
                                label: i2,
                                children:children2
                            })
                        }
                    }
                }
                regionOptions.push({
                    value: i,
                    label: i,
                    children:children1
                }); 
            }
        }
    } 
    return regionOptions
}

/* 
  递归西藏城市json数据返回 区域代码
  arr 为传入的json值
  val 为选择的区域值
*/

export function cityCode(val,arr) {
     for( let i in arr ) { 
      if(i !== 'AREAID'&&i !== 'NAMECN'){
          if ( typeof arr[i] === "object" ) {
              for( let i2 in arr[i] ) {
                  if(i2 !== 'AREAID'&&i2 !== 'NAMECN'){
                      if ( typeof arr[i][i2] === "object" ) {
                          for( let i3 in arr[i][i2] ) {
                              if(i3 !== 'AREAID'&&i3 !== 'NAMECN'){
                                  if ( typeof arr[i][i2][i3] === "object" ) {
                                    if(arr[i][i2][i3].NAMECN == val){
                                      return arr[i][i2][i3].AREAID
                                    }
                                  }
                              }
                          }
                         
                      }
                  }
              }
          }
      }
  } 
  }