import router from './router'
// import store from './store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken, toSso } from '@/utils/auth' // get token from cookie
import store from "@/store";
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

// const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
    // start progress bar
    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()
    if (to.query && to.query.token) {
        setToken(to.query.token);
        store.dispatch("user/userInfo").then(() => {
            next(to.path);
        });
        NProgress.done()
    } else if ((hasToken === 'undefined' || hasToken == null || hasToken === '') && (to.path != "/login")){
        next('/login');
        // toSso();
        NProgress.done()
    } else {
        next()
    }
    /*if (hasToken) {
        next()
        NProgress.done()
    } else {
        if (to.path === '/login') {
            next()
        } else {
            next('/login')
        }

        /!* has no token*!/
        // if (whiteList.indexOf(to.path) !== -1) {
        //     // in the free login whitelist, go directly
        //     next()
        // } else {
        //     // other pages that do not have permission to access are redirected to the login page.
        //     // next(`/login?redirect=${to.path}`)
        //     next(`/login`)
        //     NProgress.done()
        // }
    }*/
})

router.afterEach(() => {
    NProgress.done()
})
