const getters = {
  setting: (state) => state.setting,
  global: (state) => state.global,
  clientId: (state) => state.user.clientId,
  token: state => state.user.token,
  isInitial: state => state.user.isInitial,
  isExpire: state => state.user.isExpire,
  isCanDownload: state => state.user.isCanDownload === 1,
  ipParams: state => state.global.ipParams,
};
export default getters;
