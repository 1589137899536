//获取默认日期,格式2022-08-03
const getToday = ()=>{
  let date = new Date()
  let year = date.getFullYear().toString()
  let monthDay =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString()
  let da =
    date.getDate() < 10
      ? "0" + date.getDate().toString()
      : date.getDate().toString()
  let today = year + "-" + monthDay + "-" + da //当天
  return today
}
const state = {
  month: new Date().getTime(),
  time: 0,
  ipParams: '',
  dateModel: getToday(),
  warehouseCode: ''
}

const mutations = {
  CHANGE_GLOBAL: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
}

const actions = {
  changeGlobal({ commit }, data) {
    commit("CHANGE_GLOBAL", data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
