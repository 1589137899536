import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/index.vue"),
  },
  {
    path: "/journal",
    name: "journal",
    component: () => import("@/views/login/journal.vue"),
  },
];

export function resetRouter() {
  // eslint-disable-next-line no-undef
  // const newRouter = createRouter()
  // router.matcher = newRouter.matcher // reset router
}

const router = new Router({
  routes,
});
export default router;
