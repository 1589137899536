import { loginOauth, logout } from "@/api/login";
// import { toLogin } from '@/api/collect'
import { getUserInfo, userInfo } from "@/api/users";
import {
  getToken,
  setToken,
  removeToken,
  getclientId,
  setclientId,
  getIsCanDownload,
  setIsCanDownload,
  removeIsCanDownload
} from "@/utils/auth";
import { ClientId } from "@/utils/index";
import router, { resetRouter } from "@/router";

const state = {
  token: getToken(),
  name: "",
  roles: [],
  privileges: [],
  isInitial: 0, // 是否是初始密码
  isExpire: 0, // 密码是否过期
  clientId: getclientId(), // 客户端id
  isCanDownload: getIsCanDownload() // 是否可以下载: 0 1
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PRIVILEGES: (state, privileges) => {
    state.privileges = privileges;
  },
  SET_ISINITIAL: (state, isInitial) => {
    state.isInitial = isInitial;
  },
  SET_ISEXPIRE: (state, isExpire) => {
    state.isExpire = isExpire;
  },
  SET_CLIENTID: (state, clientId) => {
    state.clientId = clientId;
  },
  SET_ISCANDOWNLOAD: (state, isCanDownload) => {
    state.isCanDownload = isCanDownload;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginOauth(userInfo)
        .then((response) => {
          const { token, isExpire, isInitial, canDownload:isCanDownload } = response.data || response;
          commit("SET_ISEXPIRE", isExpire); // token是否过期
          commit("SET_ISINITIAL", isInitial);
          commit("SET_TOKEN", token);
          commit("SET_ISCANDOWNLOAD", isCanDownload);
          setToken(token);
          setIsCanDownload(isCanDownload)
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

    userInfo({ commit }) {
      return new Promise((resolve, reject) => {
          userInfo()
              .then((response) => {
                  const { token, isExpire, isInitial, canDownload:isCanDownload, username, account } = response.data || response;
                  commit("SET_ISEXPIRE", isExpire); // token是否过期
                  commit("SET_ISINITIAL", isInitial);
                  commit("SET_TOKEN", token);
                  commit("SET_ISCANDOWNLOAD", isCanDownload);
                  setToken(token);
                  setIsCanDownload(isCanDownload)
                  localStorage.setItem("username", account);
                  localStorage.setItem("usernameTwo", username);
                  resolve(response);
              })
              .catch((error) => {
                  console.error(error);
                  reject(error);
              });
      });
  },

  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo({})
        .then((response) => {
          const { data } = response;

          if (!data) {
            reject("Verification failed, please Login again.");
          }
          const { privileges, roles, name, isInitial, isExpire } = data;

          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject("getInfo: roles must be a non-null array!");
          }

          commit("SET_PRIVILEGES", privileges);
          commit("SET_ISINITIAL", isInitial); // 是否为初始密码
          commit("SET_ISEXPIRE", isExpire); // token是否过期
          commit("SET_ROLES", roles);
          commit("SET_NAME", name);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", []);
          removeToken();
          resetRouter();
          localStorage.clear();
          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch("tagsView/delAllViews", null, { root: true });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      removeToken();
      removeIsCanDownload()
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("isChooseFlag");
      resolve();
    });
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const token = role + "-token";

      commit("SET_TOKEN", token);
      setToken(token);

      const { roles } = await dispatch("getInfo");

      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch("permission/generateRoutes", roles, {
        root: true,
      });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch("tagsView/delAllViews", null, { root: true });

      resolve();
    });
  },

  generateclientId({ commit }) {
    return new Promise((resolve) => {
      const clientId = ClientId();
      commit("SET_CLIENTID", clientId);
      setclientId(clientId);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
