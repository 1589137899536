import {
  Input,
  Select,
  Option,
  Button,
  Menu,
  Message,
  MessageBox,
  Dialog,
  Form,
  FormItem,
  DatePicker,
  Table,
  TableColumn,
  Pagination,
  Loading,
  Cascader,
  Badge,
  Switch,
  Radio,
  Popover,
  Notification,
  Rate,
  Upload,
  Carousel,
  CarouselItem,
  Image,
  RadioGroup,
  RadioButton,
  TimePicker,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from "element-ui";
import Vue from "vue";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
Vue.component(CollapseTransition.name, CollapseTransition);

const element = {
  install: function(Vue) {
    Vue.use(Input);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Button);
    Vue.use(Menu);
    Vue.use(Dialog);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(DatePicker);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Pagination);
    Vue.use(Loading); // 使用服务方式的话，只安装Loading即可
    Vue.use(Loading.directive); //  指令方式(v-loading)的话这两行都得有
    Vue.use(Switch);
    Vue.use(Cascader);
    Vue.use(Badge);
    Vue.use(Radio);
    Vue.use(Popover);
    Vue.use(Rate);
    Vue.use(Upload);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Image);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(TimePicker);
    Vue.use(Tooltip);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);

    Vue.prototype.$message = Message;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$notify = Notification;
  },
};

export default element;
