<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'App',
  components: {
  },
  provide(){
    return {
      reload: this.reload
    }
  },
   computed: {
    ...mapGetters(["global"]),
  },
  data(){
    return{
      timer:null,
      isRouterAlive: true
    }
  },
  mounted(){
      this.timer = setInterval(this.countDown, 600000);
      this.$store.dispatch('global/changeGlobal', {
        key: 'month',
        value: new Date().getTime()
      })
  },
  methods:{
    countDown(){
      let value = this.global.time
      value += 600000
      this.$store.dispatch("global/changeGlobal", { key: "time", value });
      if(this.global.time === 3600000){
        location.reload(true)
        clearInterval(this.timer);
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  },
   beforeDestroy() {
      clearInterval(this.timer);
    }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  height: 100%;
  width: 100%;
}
</style>
