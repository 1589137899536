import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import element from "./requireElement";
import "@/style/index.scss";
import elTableAdminPage from "./components/elTableAdminPage/index.vue";
import "./permission";
import * as echarts from "echarts";
import { monitorGetData } from "@/api/collect.js";
import * as filters from "./filters"; // global filters
import directive from '@/directive/permission/index.js'
import warehouseCodeMixin from './mixins/warehouseCode.js'
Vue.mixin(warehouseCodeMixin)
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
Vue.use(element);
Vue.use(directive);
Vue.component("elTableAdminPage", elTableAdminPage);
Vue.prototype.$echarts = echarts;
Vue.prototype.$getData = monitorGetData;

// 生成客户端id
if (!store.getters.clientId) {
  store.dispatch("user/generateclientId");
}

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
